import styled from "styled-components";

export const AccountListFormStyled = styled.div`
  .profile_list_container {
    margin-top: 2rem;
    margin-bottom: 3rem;
    .profile_item {
      margin-bottom: 1rem;
      border-radius: 2rem;
      border: 1px solid ${({ theme }) => theme.COLORS.PURPLE_1};
      padding: 0.75rem 1rem;
      cursor: pointer;
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
      white-space: pre-wrap;
      &.active {
        background: ${({ theme }) => theme.COLORS.PURPLE_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
    }
  }
  .action_row {
    margin: auto;
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    .btn_wrap {
      width: 100%;
      max-width: 180px;
    }
  }
`;
