import styled from "styled-components";

export const VerifyOTPFormStyled = styled.div`
  .v_otp_title {
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_3};
  }
  .v_otp_sub_title {
    margin-bottom: 16px;
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    color: ${({ theme }) => theme.COLORS.GRAY_2};
  }
  .v_otp_code_row {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    .cr_left_col {
      flex: 1;
    }
    .cr_right_col {
      flex-shrink: 0;
      width: 130px;
    }
  }
  .otp_detail {
    margin-bottom: 16px;
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S13};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_LIGHT};
    color: ${({ theme }) => theme.COLORS.GRAY_1};
  }
  .v_otp_action_row {
    display: flex;
    column-gap: 16px;
    .v_otp_a_col {
      flex: 1;
    }
  }
`;
