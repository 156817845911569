import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TextFieldStyled } from "./styled";
import { useTranslation } from "react-i18next";

const TextField = ({
  theme_standard,
  label,
  errors,
  disabled,
  isRequire,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const { t } = useTranslation(["common"]);
  return (
    <TextFieldStyled className={customClass} disabled={disabled}>
      {label && (
        <div className="label">
          {label} {isRequire && <span> *</span>}
        </div>
      )}
      <input {...props} disabled={disabled} />
      {errors && <div className="errors">{t(errors, { ns: "common" })}</div>}
    </TextFieldStyled>
  );
};

TextField.propTypes = {};

export default TextField;
