import React from "react";
import { PreRegisterStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { withTranslation } from "react-i18next";
import { ROUTE_PATH } from "utils/constants/routePath";
import queryString from "query-string";
import { userService } from "apiServices";
import { RENDER_GENDER_TYPE } from "utils/functions/customer";
import { Displays, Forms } from "components";
import moment from "moment";
import { toast } from "react-toastify";

class PreRegister extends React.Component {
  state = {
    step: 1,
  };

  handleClickFirstRegis = () => {
    let { lineID, avatar } = queryString.parse(
      this.props.router.location.search
    );
    this.props.router.navigate(
      `${ROUTE_PATH.REGISTER}?is_regis=true&lineID=${lineID}&avatar=${avatar}`
    );
  };

  handleSyncLineSuccess = () => {
    this.props.router.navigate(ROUTE_PATH.SPLASH);
  };

  handleCancel = () => {
    this.setState({
      step: 1,
    });
  };

  handleFetchStep2 = (values) => {
    this.setState({
      accountData: values.map((e) => ({
        ...e,
        label: `ชื่อ-นามสกุล : ${e.first_name} ${e.last_name}\nเพศ : ${
          RENDER_GENDER_TYPE(e.gender)?.labelTh
        }\nเบอร์โทรศัพท์ : ${e.phone_number}\nอีเมล : ${
          e.email || "-"
        }\nจังหวัด : ${e.province}\nวันที่สมัคร : ${moment(e.created_at).format(
          "DD/MM/YY"
        )}`,
        labelEn: `Name : ${e.first_name} ${e.last_name}\nGender : ${
          RENDER_GENDER_TYPE(e.gender)?.labelTh
        }\nPhone Number : ${e.phone_number}\nEmail : ${
          e.email || "-"
        }\nProvince : ${e.province}\nRegisted date : ${moment(
          e.created_at
        ).format("DD/MM/YY")}`,
        value: e._id,
      })),
      step: 2,
    });
  };

  handleSubmit = async (values) => {
    let { lineID } = queryString.parse(this.props.router.location.search);
    this.setState({
      isBgLoading: true,
    });
    let params = {
      customer_id: values.account_id._id,
      line_id: lineID,
      phone_number: values.account_id.phoneNumberInput,
    };
    let res = await userService.POST_CUSTOMER_SYNC_LINE(params);
    if (res && res.status === 200) {
      this.setState({
        isBgLoading: false,
      });
      toast.success("รีเซ็ตสำเร็จ");
      this.handleSyncLineSuccess();
    } else {
      toast.error("เกิดข้อผิดพลาดในการรีเซ็ต กรุณาติดต่อเจ้าหน้าที่");
    }
  };

  render() {
    const { t, i18n } = this.props;
    let { lineID } = queryString.parse(this.props.router.location.search);
    const { isBgLoading, accountData, step } = this.state;
    return (
      <PreRegisterStyled>
        <Displays.BGLoading visible={isBgLoading} />
        <img
          className="logo_img"
          alt="yamaha logo"
          src="/assets/images/logos/logo.png"
        />
        {step === 1 && (
          <>
            <div className="title">
              {i18n.language === "en"
                ? "Welcome to Siam Music Members."
                : "ยินดีต้อนรับสู่ระบบ Siam Music Members"}
            </div>
            <div className="sub_title">
              {i18n.language === "en"
                ? "For registered members,\nplease verify your identity again."
                : "สำหรับท่านที่เคยลงทะเบียนสมาชิกแล้ว\nกรุณายืนยันตัวตนอีกครั้ง"}
            </div>
            {/* <div className="mark">
              {i18n.language === "en"
                ? "(If you have any questions or need assistance,\nfeel free to reach out to us on our LINE Official Account.)"
                : "(หากมีคำถามหรือต้องการความช่วยเหลือ\n สามารถสอบถามเพิ่มเติมได้ทาง LINE OA)"}
            </div> */}
            <div className="section">
              <Forms.PhoneNumberForm
                lineID={lineID}
                onStep2={this.handleFetchStep2}
                onSuccess={this.handleSyncLineSuccess}
              />
            </div>
            <div className="or_label">{t("OR", { ns: "common" })}</div>
            <div
              className="btn_first_regis"
              onClick={this.handleClickFirstRegis}
            >
              <div className="btn_first_label">ลงทะเบียนครั้งแรก</div>
              <div className="btn_first_label">First-time Registration</div>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="title_s2">
              {i18n.language === "en"
                ? "Please select your correct information."
                : "กรุณาเลือกข้อมูลที่ถูกต้องของท่าน"}
            </div>
            <div className="sub_title_s2">
              {i18n.language === "en"
                ? "(If you have any questions or need assistance,\nfeel free to reach out to us on our LINE Official Account.)"
                : "(หากมีคำถามหรือต้องการความช่วยเหลือ\nสามารถสอบถามเพิ่มเติมได้ทาง LINE OA)"}
            </div>
            <Forms.AccountListForm
              accountOptions={accountData}
              onCancel={this.handleCancel}
              onSubmit={this.handleSubmit}
            />
          </>
        )}
      </PreRegisterStyled>
    );
  }
}

export default withTranslation(["common", "form"])(withRouter(PreRegister));
