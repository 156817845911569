export const ROUTE_PATH = {
  ROOT: "/",
  SPLASH: "/splash",
  LOGIN_ADMIN: "/login/admin",
  ACTIVITIES_ADMIN_SCAN: "/activity/scan",
  LOGIN: "/login",
  PRE_REGISTER: "/pre-register",
  REGISTER: "/register",
  PRODUCT_WARRANTEE_NOT_LOGIN: "/product-warrantee",
  PRODUCT_WARRANTEE_NOT_LOGIN_DETAIL: "/product-warrantee/:id",
  PRODUCT_WARRANTEE_SUCCESS_NOT_LOGIN: "/product-warrantee/:id/:status",
  SYSTEM: "/system",
  HOME: "/system/home",
  ACTIVITIES: "/system/home/activities",
  ACTIVITIES_DETAIL: "/system/home/activities/:id",
  ACTIVITY_FORM: "/system/home/activity-form",
  ACTIVITY_FORM_DETAIL: "/system/home/activity-form/:id",
  ACTIVITY_FORM_QUIZ: "/system/home/activity-form/:id/quiz/:question_id",
  PRODUCT_WARRANTEE: "/system/home/product-warrantee",
  PRODUCT_WARRANTEE_DETAIL: "/system/home/product-warrantee/:id",
  PRODUCT_WARRANTEE_SUCCESS_DETAIL:
    "/system/home/product-warrantee/:id/success",
  SPECIAL_POINTS: "/system/home/special-points",
  SPECIAL_POINTS_FEATURE: "/system/home/special-points/special",
  SPECIAL_POINTS_FAV: "/system/home/special-points/fav",
  SPECIAL_POINTS_FAV_QUICK_DETAIL: "/system/home/special-points/fav/test/:id",
  SPECIAL_POINTS_FAV_QUICK_SUCCESS_DETAIL:
    "/system/home/special-points/fav/test/:id/success",
  SPECIAL_POINTS_GAME: "/system/home/special-points/game",
  PDPA: "/pdpa",
  POINT_REDEEM: "/system/point-redeem",
  POINT_REDEEM_DETAIL: "/system/point-redeem/detail/:id",
  POINT_REDEEM_SHIPPING_DETAIL: "/system/point-redeem/detail/:id/shipping",
  POINT_REDEEM_QRCODE_DETAIL: "/system/point-redeem/detail/:id/qrcode",
  POINT_REDEEM_E_COUPON: "/system/point-redeem/e-counpon",
  POINT_REDEEM_VOUCHER: "/system/point-redeem/voucher",
  POINT_REDEEM_GIFT: "/system/point-redeem/gift",
  HISTORY: "/system/history",
  NEW: "/system/new",
  NEW_DETAIL: "/system/new/:id",
  PROFILE: "/system/profile",
  PROFILE_ACTIVITY_HISTORY: "/system/profile/activity-history",
  PROFILE_REDEEEM_HISTORY: "/system/profile/redeem-history",
  PROFILE_ME: "/system/profile/me",
};
