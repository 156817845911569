import React, { useEffect } from "react";
import { QuickTestActivityFormStyled } from "./styled";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { IMG_PATH } from "utils/constants/imgPath";
import { Buttons, Inputs } from "components";

const createQuestionSchema = (question) => {
  let schema = yup.object().shape({
    // pdpa_ref_id: Yup.string().required("PDPA reference ID is required"),
    // question: Yup.string().required("Question text is required"),
    // type: Yup.number().required("Question type is required"),
    // img_path: Yup.string().nullable(),
    // answer_list: Yup.array().of(Yup.string()).nullable(),
    // answer: yup.string().nullable(),
    // is_require: yup.boolean().required("Is require is required"),
  });

  // ถ้าคำถามเป็น required, เพิ่มการตรวจสอบเพิ่มเติมสำหรับคำตอบ
  if (question.is_require) {
    schema = schema.shape({
      answer: yup.mixed().test("is-string-or-array", "required", (value) => {
        return (
          typeof value === "string" ||
          Array.isArray(value) ||
          typeof value === "object"
        );
      }),
    });
  }

  return schema;
};

const QuickTestActivityForm = ({
  currentPage,
  initialValue,
  totalPage,
  onBack,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    questions: yup
      .array()
      .of(yup.lazy((question) => createQuestionSchema(question)))
      .required("Questions are required"),

    // questions: yup.array().of(
    // yup.object().shape({
    //   answer: yup.mixed().test("is-string-or-array", "required", (value) => {
    //     return (
    //       typeof value === "string" ||
    //       Array.isArray(value) ||
    //       typeof value === "object"
    //     );
    //   }),
    // })
    // ),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    fields,
    // append, remove
  } = useFieldArray({
    control,
    name: "questions",
  });

  const watchQuestion = watch("questions");

  useEffect(() => {
    if (initialValue) {
      reset({
        ...initialValue,
      });
    }
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const { t } = useTranslation(["common"]);

  const _handleSubmit = (values) => {
    let isErr = false;
    if (values.questions && values.questions.length > 0) {
      values.questions.forEach((e, i) => {
        if (
          e.is_require &&
          (e.answer === "Other" ||
            e.answer === "อื่นๆ" ||
            e.answer === "อื่นๆ:") &&
          !e.other
        ) {
          isErr = true;
          setError(`questions[${i}].other`, {
            type: "custom",
            message: "require",
          });
        }
      });
      if (!isErr) {
        onSubmit(values);
      }
    }
  };

  return (
    <QuickTestActivityFormStyled>
      {initialValue && (
        <form onSubmit={handleSubmit(_handleSubmit)}>
          {fields.map((item, index) => (
            <div
              key={item.id}
              className={`question_item_layout ${
                fields.length === index + 1 ? "is_last" : ""
              }`}
            >
              <div className="f_question_layout">
                <div
                  className="question_label"
                  dangerouslySetInnerHTML={{
                    __html: `${item.question}${
                      item?.is_require
                        ? `<div style="margin-top:4px; font-size: 11px; color: red; text-align:right">${t(
                            "*Required",
                            { ns: "common" }
                          )}</div>`
                        : ""
                    }`,
                  }}
                />
                {/* {item?.is_require && <span>*</span>} */}
              </div>
              {item.img_path && (
                <img
                  className="fq_img"
                  alt="question img"
                  src={IMG_PATH + item.img_path}
                />
              )}
              <div className="answer_block">
                {item.type === "1" && (
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Radio
                        {...field}
                        options={
                          item.answer_list
                            ? item.answer_list.map((e) => ({
                                label: e,
                                value: e,
                              }))
                            : []
                        }
                        disabled={initialValue && initialValue.isAssessment}
                        errors={
                          errors?.questions &&
                          errors?.questions[index]?.answer?.message
                        }
                      />
                    )}
                    name={`questions.${index}.answer`}
                  />
                )}
                {item.type === "2" && (
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextArea
                        {...field}
                        theme_activity_form
                        disabled={initialValue && initialValue.isAssessment}
                        errors={
                          errors?.questions &&
                          errors?.questions[index]?.answer?.message
                        }
                      />
                    )}
                    name={`questions.${index}.answer`}
                  />
                )}
                {item.type === "3" && (
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.Upload
                        {...field}
                        disabled={initialValue && initialValue.isAssessment}
                        placeholder={t("Add File or Photo", { ns: "common" })}
                        errors={
                          errors?.questions &&
                          errors?.questions[index]?.answer?.message
                        }
                      />
                    )}
                    name={`questions.${index}.answer`}
                  />
                )}
                {item.type === "4" && (
                  <div className="pdpa_layout">
                    <div className="pdpa_title">
                      {item?.pdpa_details?.privacy_title}
                    </div>
                    <div className="pdpa_sub_title">
                      {item?.pdpa_details?.privacy_body}
                    </div>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Inputs.Radio
                          {...field}
                          options={[
                            {
                              label:
                                item?.pdpa_details?.privacy_accept_label ||
                                "ยินยอม",
                              value: "1",
                            },
                            {
                              label:
                                item?.pdpa_details?.privacy_decline_label ||
                                "ไม่ยินยอม",
                              value: "2",
                            },
                          ]}
                          errors={
                            errors?.questions &&
                            errors?.questions[index]?.answer?.message
                          }
                        />
                      )}
                      name={`questions.${index}.answer`}
                    />
                  </div>
                )}
                {item.type === "5" && (
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.DateTime
                        {...field}
                        disabled={initialValue && initialValue.isAssessment}
                        errors={
                          errors?.questions &&
                          errors?.questions[index]?.answer?.message
                        }
                      />
                    )}
                    name={`questions.${index}.answer`}
                  />
                )}
                {((watchQuestion && watchQuestion[index]?.answer === "Other") ||
                  watchQuestion[index]?.answer === "อื่นๆ" ||
                  watchQuestion[index]?.answer === "อื่นๆ:") && (
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Inputs.TextField
                        {...field}
                        // disabled={formDisabled}
                        placeholder={"Other"}
                        errors={
                          errors?.questions &&
                          errors?.questions[index]?.other?.message
                        }
                      />
                    )}
                    name={`questions.${index}.other`}
                    defaultValue=""
                  />
                )}
              </div>
            </div>
          ))}
          <div className="f_action_layout">
            {currentPage > 1 && (
              <div className="action_item_wrap">
                <Buttons.BgStandard
                  theme_standard_border
                  label={t("Back", { ns: "common" })}
                  onClick={onBack}
                />
              </div>
            )}
            {currentPage < totalPage ? (
              <div className="action_item_wrap">
                <Buttons.BgStandard
                  type="submit"
                  label={t("Next", { ns: "common" })}
                />
              </div>
            ) : (
              <div className="action_item_wrap"></div>
            )}
          </div>
          {currentPage === totalPage && (
            <div className="btn_send_form">
              <Buttons.BgStandard
                height="51px"
                type="submit"
                label={t("Submit", { ns: "common" })}
              />
            </div>
          )}
        </form>
      )}
    </QuickTestActivityFormStyled>
  );
};

QuickTestActivityForm.propTypes = {};

export default QuickTestActivityForm;
