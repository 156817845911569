import AccountListForm from "./AccountListForm";
import ActivityFormQuestionForm from "./ActivityFormQuestionForm";
import ActivityQuestionform from "./ActivityQuestionform";
import EmailForm from "./EmailForm";
import FirstnameForm from "./FirstnameForm";
import LastnameForm from "./LastnameForm";
import LoginForm from "./LoginForm";
import PDPAForm from "./PDPAForm";
import PersonalInfoForm from "./PersonalInfoForm";
import PhoneForm from "./PhoneForm";
import PhoneNumberForm from "./PhoneNumberForm";
import ProductWarranteeForm from "./ProductWarranteeForm";
import ProductWarranteeCoperateNoLoginForm from "./ProductWarranteeCoperateNoLoginForm";
import ProductWarranteeNoLoginForm from "./ProductWarranteeNoLoginForm";
import ProvinceForm from "./ProvinceForm";
import QuickTestActivityForm from "./QuickTestActivityForm";
import QuickTestForm from "./QuickTestForm";
import RegisterCorperateForm from "./RegisterCorperateForm";
import RegisterCorperateOnlyForm from "./RegisterCorperateOnlyForm";
import RegisterForm from "./RegisterForm";
import ScanQRCodeForm from "./ScanQRCodeForm";
import ShippingForm from "./ShippingForm";
import VerifyOTPForm from "./VerifyOTPForm";

const EXPORT = {
  AccountListForm,
  ActivityFormQuestionForm,
  ActivityQuestionform,
  EmailForm,
  FirstnameForm,
  LastnameForm,
  LoginForm,
  PDPAForm,
  PersonalInfoForm,
  PhoneForm,
  PhoneNumberForm,
  ProductWarranteeCoperateNoLoginForm,
  ProductWarranteeForm,
  ProductWarranteeNoLoginForm,
  ProvinceForm,
  QuickTestActivityForm,
  QuickTestForm,
  RegisterCorperateForm,
  RegisterCorperateOnlyForm,
  RegisterForm,
  ScanQRCodeForm,
  ShippingForm,
  VerifyOTPForm,
};

export default EXPORT;
