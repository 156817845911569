import styled from "styled-components";

export const PreRegisterStyled = styled.div`
  .logo_img {
    margin: auto;
    margin-bottom: 40px;
    max-width: 148px;
    width: 100%;
    height: auto;
  }
  .title {
    margin-bottom: 30px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
  .sub_title {
    margin-bottom: 30px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    white-space: pre-wrap;
  }
  .mark {
    margin-bottom: 78px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    white-space: pre-wrap;
  }
  .section {
    margin-bottom: 42px;
  }
  .or_label {
    margin-bottom: 42px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
  }
  .btn_first_regis {
    border-radius: 53px;
    padding: 18px 10px;
    text-align: center;
    background: ${({ theme }) => theme.COLORS.PURPLE_1};
    cursor: pointer;
    .btn_first_label {
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
  }

  .title_s2 {
    margin-bottom: 14px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
  }
  .sub_title_s2 {
    margin-bottom: 34px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PURPLE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    white-space: pre-wrap;
  }
`;
