import React from "react";
import { LastnameFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Buttons, Inputs } from "components";

const LastnameForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    last_name: yup.string().required("*Required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation(["common", "register"]);

  return (
    <LastnameFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t("Surname", { ns: "register" })}
                errors={errors.last_name?.message}
              />
            )}
            name="last_name"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard type="submit" label={t("Edit", { ns: "common" })} />
      </form>
    </LastnameFormStyled>
  );
};

LastnameForm.propTypes = {};

export default LastnameForm;
