import styled from "styled-components";

export const BgIconPrefixStyled = styled.div`
  .btn {
    border-radius: 10px;
    border: 1px solid
      ${({ theme, disabled }) =>
        !disabled ? theme.COLORS.PURPLE_1 : theme.COLORS.GRAY_1};
    padding: 0px 0.75rem;
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    background: ${({ theme, disabled }) =>
      !disabled ? theme.COLORS.PURPLE_1 : theme.COLORS.GRAY_1};
    color: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.WHITE_1 : theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_MEDIUM};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    // &:hover {
    //   background: #165ac8;
    //   transition: 0.7s;
    // }
  }

  .theme_add {
    .btn {
      background: unset;
      color: ${({ theme }) => theme.COLORS.BLUE_6};
    }
  }

  .theme_cancel {
    .btn {
      border-color: ${({ theme }) => theme.COLORS.PURPLE_3};
      background: ${({ theme }) => theme.COLORS.PURPLE_3};
      color: ${({ theme }) => theme.COLORS.WHITE_2};
    }
  }
  .theme_stadard_btn {
  }
`;
