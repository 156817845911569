import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TextAreaStyled } from "./styled";
import { useTranslation } from "react-i18next";

const TextArea = ({
  theme_standard,
  theme_activity_form,
  theme_question,
  label,
  errors,
  disabled,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_question: theme_question,
    theme_activity_form: theme_activity_form,
  });
  const { t } = useTranslation(["common"]);
  return (
    <TextAreaStyled className={customClass} disabled={disabled}>
      {label && <div className="label">{label}</div>}
      <textarea {...props} disabled={disabled} />
      {errors && <div className="errors">{t(errors, { ns: "common" })}</div>}
    </TextAreaStyled>
  );
};

TextArea.propTypes = {};

export default TextArea;
