import styled from "styled-components";

export const QuickTestActivityFormStyled = styled.div`
  .input_wrap {
  }
  .passed_label {
    margin-bottom: 0.5rem;
    text-align: center;
  }
  .btn_submit_wrap {
    margin: auto;
    max-width: 145px;
  }
  .question_item_layout {
    padding: 8px 0px;
    &.is_last {
      border: none;
    }
    .f_question_layout {
      padding: 0 14px;
      .question_label {
        margin-bottom: 2px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
    .answer_block {
      padding: 0 14px;
    }
    .fq_img {
      margin-bottom: 12px;
      width: 100%;
      height: auto;
    }
  }
  .f_action_layout {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    column-gap: 8px;
    .action_item_wrap {
      flex-basis: 50%;
    }
  }

  .pdpa_layout {
    .pdpa_title {
      margin-bottom: 4px;
      color: ${({ theme }) => theme.COLORS.PURPLE_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_BOLD};
    }
    .pdpa_sub_title {
      margin-bottom: 12px;
      color: ${({ theme }) => theme.COLORS.BLACK_2};
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.PROMPT_REGULAR};
    }
  }
  .btn_send_form {
    margin: auto;
    margin-top: 40px;
    max-width: 160px;
  }
`;
