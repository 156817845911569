import React from "react";
import { AccountListFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Buttons } from "components";

const AccountListForm = ({ accountOptions, onCancel, onSubmit }) => {
  const schema = yup.object().shape({
    account_id: yup.object().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { i18n, t } = useTranslation(["common"]);
  return (
    <AccountListFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          render={({ field }) => (
            <div className="profile_list_container">
              {accountOptions &&
                accountOptions.map((e, i) => (
                  <div
                    key={i}
                    className={`profile_item ${
                      field.value?._id === e.value ? "active" : ""
                    }`}
                    onClick={() => field.onChange(e)}
                  >
                    {i18n.language === "en" ? e.labelEn : e.label}
                  </div>
                ))}
            </div>
          )}
          name="account_id"
          defaultValue=""
        />
        <div className="action_row">
          <div className="btn_wrap">
            <Buttons.BgStandard
              type="submit"
              label={t("Accept", { ns: "common" })}
              disabled={isSubmitting || !isValid}
            />
          </div>
          <div className="btn_wrap">
            <Buttons.BgStandard
              theme_standard_border
              label={t("Cancel", { ns: "common" })}
              onClick={onCancel}
            />
          </div>
        </div>
      </form>
    </AccountListFormStyled>
  );
};

AccountListForm.propTypes = {};

export default AccountListForm;
