import Bell from "./Bell";
import BellAlert from "./BellAlert";
import Cancel from "./Cancel";
import Checkbox from "./Checkbox";
import Close from "./Close";
import Confirm from "./Confirm";
import Download from "./Download";
import Loading from "./Loading";
import MemberCoperate from "./MemberCoperate";
import MemberPerson from "./MemberPerson";
import MemberPerson2 from "./MemberPerson2";
import MenuHistory from "./MenuHistory";
import MenuHome from "./MenuHome";
import MenuNews from "./MenuNews";
import MenuPointRedeem from "./MenuPointRedeem";
import MenuProfile from "./MenuProfile";
import Music from "./Music";
import QrCodeMark from "./QrCodeMark";
import RedeemECoupon from "./RedeemECoupon";
import RedeemVoucher from "./RedeemVoucher";
import RedeemGift from "./RedeemGift";
import Resend from "./Resend";
import Scan from "./Scan";
import SpecialPoint from "./SpecialPoint";
import SubMenuActivity from "./SubMenuActivity";
import SubMenuFav from "./SubMenuFav";
import SubMenuGame from "./SubMenuGame";
import SubMenuProductWarrantee from "./SubMenuProductWarrantee";
import SubMenuSpecialPoint from "./SubMenuSpecialPoint";
import Success from "./Success";
import Voucher from "./Voucher";

const EXPORT = {
  Bell,
  BellAlert,
  Cancel,
  Checkbox,
  Close,
  Confirm,
  Download,
  Loading,
  MemberCoperate,
  MemberPerson,
  MemberPerson2,
  MenuHistory,
  MenuHome,
  MenuNews,
  MenuPointRedeem,
  MenuProfile,
  Music,
  QrCodeMark,
  RedeemECoupon,
  RedeemVoucher,
  RedeemGift,
  Resend,
  Scan,
  SpecialPoint,
  SubMenuActivity,
  SubMenuFav,
  SubMenuGame,
  SubMenuProductWarrantee,
  SubMenuSpecialPoint,
  Success,
  Voucher,
};

export default EXPORT;
