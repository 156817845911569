import React from "react";
import { FirstnameFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Buttons, Inputs } from "components";

const FirstnameForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    first_name: yup.string().required("*Required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { t } = useTranslation(["common", "register"]);

  return (
    <FirstnameFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t("Name", { ns: "register" })}
                errors={errors.first_name?.message}
              />
            )}
            name="first_name"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard type="submit" label={t("Edit", { ns: "common" })} />
      </form>
    </FirstnameFormStyled>
  );
};

FirstnameForm.propTypes = {};

export default FirstnameForm;
